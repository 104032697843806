<template>
  <div class="main-wrapper">
    <div>
      <div style="position: sticky; top: 0; z-index: 4; height: 0px">
        <div class="header__content">
          <v-container
            :style="
              isWholesale ? { maxWidth: '980px', padding: '0 !important' } : {}
            "
            :class="isWholesale ? 'py-0' : 'py-0'"
          >
            <v-row no-gutters class="header__down">
              <v-col v-if="$vuetify.breakpoint.smAndDown">
                <!-- <div class="language-dropdown py-1">
                  <div class="language-dropdown-toggle">{{
                    localeLang.toUpperCase()
                  }}</div>
                  <ul class="language-dropdown-menu pa-0">
                    <li v-for="item in locales" :key="item.id">
                      <a @click="setLang(item?.code)">
                        {{ item.code.toUpperCase() }}
                      </a>
                    </li>
                  </ul>
                </div> -->
                <div
                  v-if="!$vuetify.breakpoint.smAndDown"
                  class="language-dropdown py-1"
                >
                  <div class="language-dropdown-toggle">{{ localeLang }}</div>
                  <ul class="language-dropdown-menu pa-0">
                    <li v-for="item in locales" :key="item.id">
                      <a @click="setLang(item?.code)">
                        {{ item.code }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="google_translate_element">
                  <!-- <v-google-translate :languages="languages" /> -->
                </div>
              </v-col>

              <v-col cols="2" class="logo">
                <img
                  width="90"
                  height="58px"
                  src="/img/tumar__logo--header.svg"
                  alt="tumar__logo--header"
                  title="tumar__logo--header"
                  @click="$router.push('/')"
                />
              </v-col>

              <v-col
                v-if="$vuetify.breakpoint.smAndDown"
                cols="5"
                class="nav__menu py-0"
              >
                <ul class="pa-0">
                  <li
                    v-for="menu in menu.bottomLeft.length && menu.bottomLeft"
                    :key="menu.id"
                    class="nav__link-dropdown"
                  >
                    <router-link class="nav__link" :to="`${menu.url}`">
                      {{ menu.title }}
                    </router-link>
                    <MenuRecursion :array="menu.childrens" />
                  </li>
                </ul>
              </v-col>

              <v-col
                :cols="$vuetify.breakpoint.smAndDown ? 1 : 10"
                class="nav__menu nav__right"
              >
                <ul class="pa-0">
                  <li
                    v-for="(mennu, i) in menu.bottomRight.length &&
                    menu.bottomRight"
                    :key="mennu.id"
                    class="nav__link-dropdown"
                  >
                    <router-link class="nav__link" :to="`${mennu.url}`">
                      {{ mennu?.title }}
                    </router-link>
                    <MenuRecursion v-if="i <= 2" :array="mennu.childrens" />
                    <MenuRecursion v-else left :array="mennu.childrens" />
                  </li>
                </ul>

                <div
                  id="google_translate_element"
                  class="pk"
                  v-if="!$vuetify.breakpoint.smAndDown"
                >
                  <!-- <v-google-translate :languages="languages" /> -->
                </div>

                <div>
                  <div
                    v-if="!searchModal"
                    class="search"
                    @click="openSearchModal"
                  ></div>
                  <v-icon
                    v-else
                    @click="searchModal = false"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: 23px;
                      margin-left: 25px;
                    "
                    >mdi-close</v-icon
                  >
                </div>
                <div
                  ><router-link
                    class="user"
                    :to="{
                      name: isCraftsmanLogged ? 'craftsman-profile' : 'chat',
                    }"
                  ></router-link>
                </div>
                <div v-if="!isCraftsmanLogged">
                  <div class="basket" @click="toggleSideCart(true)">
                    <span v-if="cartItemsCount">{{ cartItemsCount }}</span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <v-dialog fullscreen v-model="menuView">
        <div class="burger-wrapper">
          <v-toolbar :elevation="0">
            <v-spacer></v-spacer>
            <v-btn text :ripple="false" @click="menuView = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <ul class="burger-menu">
            <v-treeview
              dense
              :items="[
                // ...menu.bottomLeft,
                ...menu.bottomRight,
                // ...menu.topLeft,
                // ...menu.topRight,
              ]"
              activatable
              selection-type="independent"
              return-object
              item-children="childrens"
            >
              <template v-slot:label="{ item }">
                <li class="burger-menu-dropdown">
                  <router-link class="burger__link" :to="`${item.url}`">
                    {{ item.title }}
                  </router-link>
                </li>
              </template>
            </v-treeview>
          </ul>
        </div>
      </v-dialog>
      <v-main>
        <transition name="slide-fade" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
        <div
          v-if="isSideCartVisible"
          class="overlay"
          @click="toggleSideCart(false)"
        ></div>
        <transition name="slide-left">
          <SideCart
            v-if="isSideCartVisible"
            :cartItems="cartItems"
            @toggleSideCart="toggleSideCart"
            @removeCartItem="removeCartItem"
            @openBasket="
              $router.push({
                name: 'store basket',
                params: { items: cartItems },
              })
            "
            @goToProduct="
              (id) =>
                $router
                  .push({ name: 'Product', params: { id: id } })
                  .catch(() => {})
            "
          />
        </transition>
      </v-main>
      <div class="menuBottom">
        <div>
          <router-link :to="homeHref">
            <v-icon>mdi-home</v-icon>
          </router-link>
        </div>
        <div>
          <div class="search" @click="openSearchModal"></div>
        </div>
        <div>
          <v-btn
            text
            :ripple="false"
            @click="menuView = true"
            style="min-width: unset; padding: 0px !important"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
        <div>
          <v-icon @click="showChatbot = true">mdi-message-text</v-icon>
        </div>
        <div>
          <div class="basket" @click="toggleSideCart(true)">
            <span v-if="cartItemsCount">{{ cartItemsCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="chat"
      :style="screenWidth < 980 && !showChatbot && { display: 'none' }"
    >
      <ChatBot
        v-if="showChatbot || !screenWidth < 980"
        @closeChatbot="showChatbot = false"
        @showChatbot="showChatbotOn"
      />
    </div>
    <div v-if="searchModal" class="search-modal">
      <div class="search-modal__content">
        <v-text-field
          ref="searchInput"
          dense
          v-model="searchText"
          @keydown.enter="submitSearch"
          :class="`search-modal-input `"
          placeholder="Search for products"
        />
        <div class="b_txt">
          <p class="search__text"
            >Start typing to see products you are looking for.</p
          >
          <button @click="submitSearch" class="search-button">Найти</button>
        </div>
        <v-icon
          @click="closeIconWithIcon"
          style="font-size: 48px; line-height: 50px"
          :class="`search-modal__close `"

          >mdi-close</v-icon
        >
        <section class="products d-block mt-2">
          <v-tabs-items>
            <div class="pa-0 w-100">
              <transition name="slide-fade" mode="out-in">
                <v-row key="1" class="list_product width-product">
                  <v-col
                    v-for="product in items"
                    :key="product.id"
                    cols="6"
                    md="4"
                    class="pa-3"
                  >
                    <ProductCard
                      :product="product"
                      :price="product.price"
                      :currency="product.currency"
                      @click.native="
                        () => {
                          $router.push({
                            path: `/product/${product.slug}`,
                            params: { id: product.slug },
                          })
                          setSelectedProduct(product)
                          closeModal()
                        }
                      "
                      @goToDetails="
                        () => {
                          $router.push({
                            path: `/product/${product.slug}`,
                            params: { id: product.slug },
                          })
                          setSelectedProduct(product)
                          closeModal()
                        }
                      "
                    />
                  </v-col>
                </v-row>
              </transition>
            </div>
          </v-tabs-items>
        </section>

       
      </div>
    </div>
    <div class="back_to_top text-center">
      <a @click.prevent="$vuetify.goTo(0, { duration: 0.3 })">Back to top</a>
    </div>
    <Footer />
    <!-- <div class="gallery-container"> -->
    <VueGallerySlideshow
      :images="images"
      :index="index"
      @close="index = null"
    />
    <!-- <div class="gallery-actions"> -->
      <!-- <button class="share-button" @click="shareImage">Share</button> -->
    <!-- </div> -->
  <!-- </div> -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import Footer from './Footer.vue'
import SideCart from '../sidecart/store/SideCartStore.vue'
import ChatBot from '../../content/chatbot/index.vue'
import MenuRecursion from './MenuRecursion.vue'
import ProductCard from '@/view/content/store/ProductCard'
const { mapState, mapActions } = createNamespacedHelpers('storeCart')
const { mapState: State_menu, mapActions: Actions_menu } =
  createNamespacedHelpers('menu')
const { mapState: State_products, mapActions: Actions_products, mapMutations: Mutations_products,} =
  createNamespacedHelpers('products')
const { mapActions: Actions_footerMenu } = createNamespacedHelpers('footerMenu')
const {
  mapState: State_auth,
  mapActions: Actions_auth,
  mapGetters: Getters_auth,
} = createNamespacedHelpers('auth')
import VueGallerySlideshow from 'vue-gallery-slideshow'

export default {
  name: 'StoreLayout',
  components: {
    ProductCard,
    Footer,
    SideCart,
    ChatBot,
    MenuRecursion,
    VueGallerySlideshow,
  },
  data() {
    return {
      activeLang: this.$store.state.language,
      languages: [
        {
          code: 'en',
          name: 'English',
          cname: '英语',
          ename: 'English',
        },
        {
          code: 'ru',
          name: 'Русский',
          cname: '俄语',
          ename: 'Russian',
        },
      ],
      menu: {
        topLeft: [],
        topRight: [],
        bottomLeft: [],
        bottomRight: [],
      },
      startSearch: false,
      menus: [],
      isWholesale: false,
      screenWidth: window.innerWidth,
      homeHref: '/shop',
      searchText: '',
      searchModal: false,
      showChatbot: false,
      navRightItems: [
        {
          title: 'WHOLESALE',
        },
        {
          title: 'COMPANY',
        },
      ],
      navLeftItems: [
        {
          title: 'Felt Shoes',
        },
        {
          title: 'Interior',
        },
        {
          title: 'Accessories',
        },
      ],
      dropDownItems: [
        {
          title: 'About us',
          routeName: 'About',
        },
        {
          title: 'Production process',
          routeName: 'Production',
        },
        {
          title: 'Contact us',
          routeName: 'Contact us',
        },
        {
          title: 'FAQ',
          routeName: 'FAQ',
        },
        {
          title: 'Shipment',
          routeName: '',
        },
        {
          title: 'Return / Exchange',
          routeName: '',
        },
        {
          title: 'Promotions',
          routeName: 'Categories',
          query: {
            section: 'promotions',
          },
        },
      ],
      images: [],
      index: null,
      menuView: false,
      isPageScrolled: false,
      items: [],
    }
  },
  computed: {
    ...mapState(['isSideCartVisible', 'cartItems']),
    ...State_products(['products']),
    ...State_menu(['retailUrl', 'wholesaleUrl', 'retail', 'wholesale']),
    ...State_auth(['localeLang', 'locales']),
    ...Getters_auth(['isCraftsmanLogged']),
    cartItemsCount() {
      return this.cartItems.length
    },
  },
  watch: {
    '$route.fullPath'(val) {
      this.$vuetify.goTo(0, { duration: 0 })
      this.toggleSideCart(false)
    },
    isSideCartVisible(val) {
      this.createMenu()
    },
    products(val) {
      this.items = val
    },
    $route(to, from) {
      // if (
      //   this.wholesaleUrl.includes(this.$route.path) ||
      //   this.$route.path === '/'
      // ) {
      //   this.menus = this.wholesale
      //   this.isWholesale = true
      //   this.menuView = false
      //   this.homeHref = '/'
      //   this.showChatbot = false
      // } else if (window.location.href.includes('/shop')) {
      this.menus = this.retail
      this.isWholesale = false
      this.menuView = false
      this.homeHref = '/'
      this.showChatbot = false
      // } else {
      //   this.menus = this.retail
      //   this.isWholesale = false
      //   this.menuView = false
      //   this.homeHref = '/shop'
      //   this.showChatbot = false
      // }

      const myRecursiveFunction = () => {
  // eslint-disable-next-line no-console
  const images = document.querySelectorAll('img')

  const loadingImages = [...images].filter(
    (item) =>
      item.currentSrc.includes('image-loader.f668a6ab.gif') &&
      !this.images.includes(item.dataset.src)
  )
  const imageList = [...images].filter(
    (item) =>
      item.currentSrc !== '' &&
      !item.currentSrc.includes('.svg') &&
      !item.currentSrc.includes('.png') &&
      item.currentSrc.includes('https://admin.tumar.com/')
  )
  const imagesSrc = [...images]
    .map((item) => item.currentSrc)
    .filter(
      (item) =>
        item !== '' &&
        !item.includes('.svg') &&
        !item.includes('.png') &&
        item.includes('https://admin.tumar.com/')
    )
  this.images = imagesSrc
  imageList.forEach((image) => {
    // Добавляем проверку на класс no-modal
    if (!image.classList.contains('no-modal')) {
      image.addEventListener('click', (e) => {
        this.index = imagesSrc.indexOf(e.target.currentSrc)
      })
    }
  })

  if (loadingImages.length) {
    setTimeout(myRecursiveFunction, 2000)
  }
}


      setTimeout(() => {
        myRecursiveFunction()
      }, 3000)

      setTimeout(() => {
        myRecursiveFunction()
      }, 6000)

      setTimeout(() => {
        myRecursiveFunction()
      }, 9000)
    },
    menus(newValue) {
      this.menu.topLeft = this.menus?.filter(
        (el) => el?.block_code === 'top_left'
      )
      this.menu.topRight = this.menus?.filter(
        (el) => el?.block_code === 'top_right'
      )
      this.menu.bottomLeft = this.menus?.filter(
        (el) => el?.block_code === 'bottom_left'
      )
      this.menu.bottomRight = this.menus?.filter(
        (el) => el?.block_code === 'bottom_right'
        //
      )
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions(['toggleSideCart', 'removeCartItem', 'setSelectedProduct']),
    ...Actions_products([
      'searchProducts',
      'getAllProducts',
      'productByCatigory',
    ]),
    // ...State_products(['products']),
    ...Actions_menu(['fetchMenus']),
    ...Actions_footerMenu(['fetchFooterMenus']),
    ...Actions_auth(['setLocaleLang', 'fetchLocales']),
    ...Mutations_products(['CLEAN_PRODUCTS']),
    isScrolled() {
      return window.pageYOffset > 0
    },
    handleScroll() {
      this.isPageScrolled = this.isScrolled()
    },
    handleResize() {
      this.screenWidth = window.innerWidth
    },
    closeModal() {
      this.searchModal = false
    },
    openSearchModal() {
      this.searchModal = true
      this.CLEAN_PRODUCTS()
      this.$nextTick(() => {
        const input = this.$refs.searchInput
        // setTimeout(() => {
        //   input.focus()
        // }, 1)
      })
    },
    closeIconWithIcon() {
      this.searchModal = false
      location.reload();
    },
    submitSearch() {
      // this.searchModal = false
      // this.$router.push({
      //   name: 'Search',
      //   params: {
      //     search: this.searchText,
      //   },
      // })
      this.startSearch = true
      this.searchProducts(this.searchText.toLowerCase())

      // console.log(this.products,'getAllProducts');
    },
    showChatbotOn(params) {
      return params
    },
    setLang(lang) {
      if (lang === this.localeLang) {
        return
      }
      this.setLocaleLang(lang)
      this.$store.dispatch('footerMenu/fetchFooterMenus', lang)
      this.$store.dispatch('menu/fetchMenus', lang)
    },
    createMenu() {
      // if (
      //   this.wholesaleUrl.includes(this.$route.path) ||
      //   this.$route.path === '/'
      // ) {
      //   this.menus = this.wholesale
      //   this.isWholesale = true
      //   this.homeHref = '/'
      // } else if (this.$route.path === '/shop') {
      this.menus = this.retail
      this.isWholesale = false
      this.menuView = false
      this.homeHref = '/'
      this.showChatbot = false
      // } else {
      //   this.menus = this.retail
      //   this.isWholesale = false
      //   this.homeHref = '/shop'
      // }
    },
    shareImage() {
      // Логика для кнопки Share
      console.log("Image shared!");
      // Здесь можно добавить код для открытия модального окна, копирования ссылки или использования API для sharing.
    },
  },
  async mounted() {
    await this.fetchFooterMenus()
    await this.fetchMenus()
    this.createMenu()

    this.isPageScrolled = this.isScrolled()

    window.addEventListener('resize', this.handleResize)

    window.addEventListener('scroll', this.handleScroll)

    const myRecursiveFunction = () => {
  // eslint-disable-next-line no-console
  const images = document.querySelectorAll('img')
  var links = document.querySelectorAll('a[href*="#"]')

  // eslint-disable-next-line no-console
  // Добавляем обработчик события клика для каждой ссылки
  links.forEach(function (link) {
    // eslint-disable-next-line no-console
    // link.removeAttribute('target');
    link.addEventListener('click', function (event) {
      // Отменяем действие по умолчанию (переход по ссылке)
      event.preventDefault()
    })
  })

  const loadingImages = [...images].filter(
    (item) =>
      item.currentSrc.includes('loader.a6178f44.gif') &&
      !this.images.includes(item.dataset.src)
  )
  const imageList = [...images].filter(
    (item) =>
      item.currentSrc !== '' &&
      !item.currentSrc.includes('.svg') &&
      !item.currentSrc.includes('.png') &&
      item.currentSrc.includes('https://admin.tumar.com/')
  )
  const imagesSrc = [...images]
    .filter((item) => item.alt !== 'product-img')
    .map((item) => item.currentSrc)
    .filter(
      (item) =>
        item !== '' &&
        !item.includes('.svg') &&
        !item.includes('.png') &&
        item.includes('https://admin.tumar.com/')
    )
  this.images = imagesSrc
  imageList.forEach((image) => {
    // Добавляем проверку на класс no-modal
    if (!image.classList.contains('no-modal')) {
      image.addEventListener('click', (e) => {
        // eslint-disable-next-line no-console
        this.index = imagesSrc.indexOf(e.target.currentSrc)
      })
    }
  })

  if (loadingImages.length) {
    // eslint-disable-next-line no-console
    setTimeout(myRecursiveFunction, 2000)
  }
}


    setTimeout(() => {
      myRecursiveFunction()
    }, 3000)

    setTimeout(() => {
      myRecursiveFunction()
    }, 6000)

    setTimeout(() => {
      myRecursiveFunction()
    }, 9000)
  },
  created() {
    this.fetchLocales()
  },
}
</script>
<style lang="scss" scoped>
.search-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 90px;
}
.products .list_product .list_items .p_img {
  height: 156px;
  width: 100%;
  min-height: 156px;
  max-height: 156px;
}

.products .list_product .list_items .p_img img {
  object-fit: cover;
}

.v-application .pa-3 {
  padding: 5px !important;
}

.products .list_product .list_items .p_text {
  padding: 5px 0;
}
.products {
  overflow-y: scroll !important;
  height: 700px !important;
}
.header__content:before {
  content: '';
  background: #edf1ef;
  width: 100%;
  height: 20px;
  position: relative;
  display: block;
}
.header-down {
  height: 0px;
  position: static;
}
.header__content {
  height: 84px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #ffffff8a;
  z-index: 300;
}
.menuBottom {
  display: none;
}
.chat {
  position: fixed;
  bottom: 0px;
  right: 20px;
  z-index: 1000;
}
@media (max-width: 979px) {
  .chat {
    position: fixed;
    bottom: 0px;
    right: 20px;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .chat_mobile_unactive {
    width: 0;
    height: 0;
  }
  .search-modal {
    top: 0 !important;
  }
}
.search-modal {
  z-index: 20;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  background-color: #fff;
  align-items: center;
  overflow-y: scroll;
  overflow: scroll !important;
}
.v-text-field--outlined >>> fieldset:active .v-text-field--outlined {
  border-color: #0f0f0f;
}
.search-modal__content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.search-modal__close {
  position: absolute;
  top: 0px;
  right: 20px;
  background-color: #ffffff00;
}
.search-button {
  // display: block;
  // position: absolute;
  // top: 200px;
  // left: 50%;
  width: 100px;
  height: 40px;
  background-color: rgb(207, 235, 204);
  margin-top: 20px;
  border-radius: 5px;
  transition: 0.5s;
  // margin-left: -50px;
}
.search-button:hover {
  background: rgb(192 218 189);
}
.search__text {
  // display: block;
  // position: absolute;
  // top: 130px;
  // left: 50%;
  // transform: translate(-50%, 0);
  // width: auto;
  // white-space: nowrap;
}
.b_txt {
  text-align: center;
}
.gallery-container {
  position: relative;
}

.gallery-actions {
  position: absolute;
  top: 10px;
  right: 10px;
}

.share-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.share-button:hover {
  background-color: #0056b3;
}

@media (max-width: 979px) {
  .search-modal__close {
    top: 20px;
    font-size: 25px !important;
    line-height: 25px !important;
  }
  .search-button {
    display: block;
    position: absolute;
    top: calc(50% + 100px);
    left: 50%;
    width: 100px;
    height: 40px;
    background-color: #ccc;
    margin-left: -50px;
  }
  .search__text {
    display: block;
    position: absolute;
    top: calc(50% + 50px);
    left: 50%;
    transform: translate(-50%, 0);
    width: auto;
    white-space: nowrap;
  }
  .search-modal {
    z-index: 20;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #fff;
    align-items: center;
    overflow: auto;
  }
  .search-modal__content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
}

@media (max-width: 979px) {
  .chat {
    position: fixed !important;
    bottom: 0px !important;
    top: 0px !important;
    left: 0 !important;
    right: 0px !important;
    z-index: 99 !important;
  }
  .menuBottom {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    border-top: 1px solid #c6c6c6;
  }
  .menuBottom div {
    width: 35px !important;
  }
  .menu.v-btn {
    padding: 0px !important;
    min-width: 24px !important;
  }
  .search {
    padding: 0px !important;
    margin: 0px !important;
  }
  .menuBottom .mdi.mdi-menu {
    border: 1px solid #d4eee1;
    border-radius: 50%;
    padding: 5px;
    background: #edf1ef;
  }
}
.logo img {
  cursor: pointer;
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.3s ease;
}
.slide-left-leave-to,
.slide-left-enter {
  transform: translateX(100%);
}
</style>
<style>
.search-modal-input {
  text-align: center !important;
  align-items: center;
  font-size: 20px;
  border-bottom: 0px;
  font-weight: 600;
}
.search-modal-input div div div input {
  text-align: center !important;
  align-items: center !important;
  min-height: 70px !important;
  font-size: 48px;
  padding: 55px !important;
  line-height: 50px;
}
@media (max-width: 979px) {
  .search-modal-input {
    text-align: center !important;
    align-items: center;
    font-size: 20px;
    height: 50px;
    border-bottom: 0px;
    font-weight: 500;
  }
  .search-modal-input div div div input {
    text-align: center !important;
    align-items: center !important;
    height: 50px !important;
    font-size: 20px;
    padding: 25px 0px;
    line-height: 25px;
    padding: 0px !important;
  }
}
.search-modal-input div div div input::placeholder {
  color: #0f0f0f !important;
}
.search-modal-input div div div input:active {
  border: #0f0f0f;
}

.burger-menu .v-icon.v-icon.v-icon--link {
  position: absolute;
  right: 20px;
  height: 40px;
  width: 40px;
}
.burger-menu .v-treeview-node__level {
  width: 0px;
}
.v-treeview-node__children {
  -webkit-transition: all 3.8s cubic-bezier(0, 0, 1.5, 1) !important;
  transition: all 3.8s cubic-bezier(0, 0, 1.5, 1) !important;
  background-color: #cacaca26 !important;
}
.v-application--is-ltr .v-treeview-node__toggle {
  transform: none !important;
}
.v-application--is-ltr .v-treeview-node__toggle > .v-icon,
.v-application--is-ltr .v-treeview-node__toggle > .v-icon__svg {
  display: none;
}
.v-application--is-ltr .v-treeview-node__toggle:before {
  content: '+';
}
.v-application--is-ltr .v-treeview-node__toggle--open:before {
  content: '-';
}
.vgs__gallery__container__img {
  width: 60px !important;
  height: 60px !important;
}
.vgs__gallery__container__img--active {
  width: 60px !important;
  height: 60px !important;
}
.vgs__container {
  height: 70vh !important;
  background-color: #ffffff00 !important;
}
.vgs__prev,
.vgs__next {
  color: black !important;
  width: 40px !important;
  height: 40px !important;
  padding: 20px !important;
  font-size: 25px !important;
  line-height: 20px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #fff !important;
  top: 50% !important;
  transform: translate(0%, -50%) !important;
  margin-top: 0px !important;
  padding-bottom: 23px !important;
}
.vgs__close {
  font-size: 45px !important;
  color: black !important;
}
@media (max-width: 679px) {
  .vgs__container {
    top: 50% !important;
    transform: translate(0%, -50%) !important;
    margin-top: 0px !important;
  }
  .vgs__prev,
  .vgs__next {
    top: 50% !important;
    transform: translate(0%, -50%) !important;
    margin-top: 0px !important;
  }
}
.eo__dropdown__menu {
  width: 200px;
  height: 100px;
  margin: 0 10px;
}
.vgs {
  background: white !important;
}
.eo__dropdown__menu ul {
  display: flex;
  flex-direction: column;
}
</style>
